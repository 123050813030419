import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/personalAcount"
import { Banner } from "../components/Banners/ElenaPodkaminskaya"
import { Banner as RoundedDynamicBanner } from "../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRefinancing } from "../components/MoreBenefitsRefinancing"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { BannerText, getDynamicBannerText } from "../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../helpers/getDynamicBannerUrl"

import { PageData } from "../interfaces/pageProps"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { BottomCta, RefinancingBgImg } from "../components/BottomCta"
import { SwitcherTextBlock } from "../components/SwitcherTextBlock"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const GIFT_TITLE = (
  <>
    <b>Оформите Халву</b>
    <br />
    для&nbsp;удобного&nbsp;рефинансирования
  </>
)

const bannerTitle = `
Рефинансирование кредитных карт с Халвой
`
const bannerDescription = `
  — До 17% на остаток по карте
  <br />
  — Кэшбэк до 10%
  <br />— Обслуживание 0 ₽  
`

// Время для таймер(30мин)
const countDownTime = 1800000

// TODO: Пока Новую длинную заявку надо показывать только на тесте + там он еще пройдет через АБ тест
// пока поставлю переменную только, придется поменять на тип того - globalThis.KameleeonExperiment(потом)

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA additionalEventInDataLayer countDownTime={countDownTime} />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <Banner
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          orderNum="1"
        />
      )}
      <FourAdvantagesPoints withTitle indent variant="bezProcentov" orderNum="2" />

      <FormPKW
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        backgroundColor="#EBECFF"
        orderNum="3"
      />
      <HowInstallmentWorks variant="halva" headTitle="Рефинансируйте с Халвой" orderNum="4" />
      <NewCalculator additionalEventInDataLayer orderNum="5" />
      <MoreBenefitsRefinancing additionalEventInDataLayer bottomBlock={false} orderNum="6" />
      <Partners orderNum="7" />
      <BottomCta variant="refin" title={GIFT_TITLE} BgImg={RefinancingBgImg} orderNum="8" />
      <SwitcherTextBlock mode="media" orderNum="9" seoBlockText="" indent />
      <Reviews additionalEventInDataLayer orderNum="10" />

      <NewFooter ligal={ligal} orderNum="11" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/24-mesyaca-bez-procentov/media/") {
        url
        notIndex
        ligal {
          text
        }
      }
    }
  }
`
